import { Box, FlexBox, FluidImage, Icon, P } from '@vp/swan'
import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SectionProps } from '../types'
import { CarouselType } from './types'

const DEFAULT_CAROUSEL_INTERVAL = 5000

gsap.registerPlugin(ScrollTrigger)

const Carousel = ({ content }: SectionProps) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const [defaultSlide, setDefaultSlide] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const carouselItems: CarouselType[] = []

  let i = 1
  // eslint-disable-next-line array-callback-return
  content.map((item) => {
    if (item.icons && item.icons.length > 3) {
      i++
      return carouselItems.push({
        index: i,
        imgLg: String(item.icons[0].secureUrl),
        imgSm: String(item.icons[1].secureUrl),
        thumbnail: String(item.icons[2].secureUrl),
        searchText: String(item.hoverText),
        textLg: String(item.valueText),
        textBottomLg: String(item.title),
        textBottomSm: String(item.description),
      })
    }
  })

  const startInterval = () => {
    const _intervalId = setInterval(() => {
      setDefaultSlide((prevSlide) => (prevSlide === 2 ? 0 : prevSlide + 1))
    }, DEFAULT_CAROUSEL_INTERVAL)

    setIntervalId(_intervalId)
  }

  const nextSlide = (index: number) => {
    setDefaultSlide(index)

    if (intervalId !== null) { clearInterval(intervalId) }

    startInterval()
  }

  useEffect(() => {
    startInterval()
  }, [])

  useEffect(() => {
    if (isFirstLoad) {
      gsap.fromTo(
        '.top-text',
        { opacity: 0, y: -200 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.arrow-right',
        { opacity: 0, y: -200 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.carousel-bg-img',
        { opacity: 0, y: 200 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.search-yelp',
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
        }
      )

      gsap.fromTo(
        '.blue-box-title',
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.blue-box-line',
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          delay: 0.8,
        }
      )

      gsap.fromTo(
        '.blue-box-img',
        { opacity: 0, x: 50 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.blue-box-desc',
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )
    } else {
      gsap.fromTo(
        '.top-text',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.arrow-right',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.carousel-bg-img',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.search-yelp',
        { opacity: 0.5, x: 20 },
        {
          opacity: 1,
          x: 0,
          duration: 0.8,
        }
      )

      gsap.fromTo(
        '.blue-box-title',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.blue-box-line',
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          delay: 0.8,
        }
      )

      gsap.fromTo(
        '.blue-box-img',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )

      gsap.fromTo(
        '.blue-box-desc',
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
        }
      )
    }
    setIsFirstLoad(false)
  }, [defaultSlide])

  if (!carouselItems[defaultSlide]) return null

  return (
    <>
      <FlexBox
        className='bg-red bg-red-container'
        justifyContent='flex-end'
        pr={4}
      >
        <FlexBox flexDirection='column' alignItems='center'>
          <P className='top-text text-white'>
            {carouselItems[defaultSlide].textLg}
          </P>
          {content[0].icons && (
            <FluidImage
              src={content[0].icons[1]?.secureUrl}
              className='arrow-right'
              alt='Yelp Carousel arrow'
            />
          )}
        </FlexBox>

        <FluidImage
          src={carouselItems[defaultSlide].imgLg}
          className='carousel-bg-img'
          alt='Yelp Carousel Image'
        />
        <FlexBox gap={3} alignItems='center' className='search-yelp'>
          <Icon skin='white' size='20p' iconType='search' />
          {carouselItems[defaultSlide].searchText}
        </FlexBox>
      </FlexBox>

      <Box className='blue-box-container'>
        <FlexBox
          className='bg-selected-accent blue-box'
          gap={0}
          flexDirection='column'
          justifyContent='space-between'
        >
          <P className='blue-box-title'>
            {carouselItems[defaultSlide].textBottomLg}
          </P>

          {content[0].icons && (
            <FluidImage
              src={content[0].icons[0].secureUrl}
              alt='carousel line'
              className='blue-box-line'
              ml={{ xs: 2, sm: 3 }}
            />
          )}
          <P className='blue-box-desc' m={0} fontWeight='bold'>
            {carouselItems[defaultSlide].textBottomSm}{' '}
            <span className='text-citation'>
              {carouselItems[defaultSlide].index}
            </span>
          </P>

          <FluidImage
            src={carouselItems[defaultSlide].imgSm}
            className='blue-box-img'
            alt='Yelp Carousel Image'
          />
        </FlexBox>
      </Box>

      <FlexBox
        justifyContent='flex-end'
        gap={4}
        className='thumbnail-container'
      >
        {carouselItems.map((item, index) => (
          <FluidImage
            key={index}
            className='carousel__thumbnail'
            src={item.thumbnail}
            onClick={() => nextSlide(index)}
            style={{
              borderColor: index === defaultSlide ? '#000' : '#fff',
            }}
            alt='Yelp Carousel Thumbnail'
          />
        ))}
      </FlexBox>
    </>
  )
}

export default Carousel
