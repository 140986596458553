export const YELP_BUSINESS_LINK =
  'https://business.yelp.com/campaign/vista/?promo=VPDC10D300&utm_source=Vista&utm_medium=partner&utm_campaign=VistaStartYourBusiness2024&utm_content=YelpProductPage'

export const VISTA_SIGNS_POSTERS_PRODUCT_LINK = (locale: string) => {
  switch (locale.toLowerCase()) {
    case 'en-ca':
      return 'https://www.vistaprint.ca/signs-posters'
    case 'fr-ca':
      return 'https://www.vistaprint.ca/fr/produits-affichage'
    case 'es-us':
      return 'https://www.vistaprint.com/es/carteles-posteres'
    default:
      return 'https://www.vistaprint.com/signs-posters'
  }
}
