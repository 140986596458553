import {
  Button,
  FlexBox,
  Box,
  FluidImage,
  H1,
  P,
  Ul,
  Li,
  Hidden,
  Visible,
} from '@vp/swan'
import Carousel from './carousel/carousel'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SectionProps } from './types'
import { YELP_BUSINESS_LINK } from '../const/const'
import { redirectWithTracking } from '../helpers/redirectWithTracking'

gsap.registerPlugin(ScrollTrigger)

const Section1 = ({ content }: SectionProps) => {
  const carouselContent = content.filter((feature) =>
    feature.key?.toLowerCase().includes('carousel')
  )
  const logo = content[0].icons ? content[0].icons[0].secureUrl : ''
  const arrow = content[0].icons ? content[0].icons[1].secureUrl : ''
  return (
    <>
      <FlexBox
        justifyContent='space-between'
        style={{ width: '100%' }}
        className='block--section1'
      >
        <Box className='left-section-container'>
          <FluidImage src={logo} alt='Yelp Logo' className='logo' />

          <H1 my={{ xs: 4, md: 7 }} className='section1-title'>
            {content[0].title}
          </H1>
          <P>{content[0].description}</P>

          <Hidden xs sm>
            <P fontWeight='bold' fontSize='large' mt={{ xs: 4, md: 7 }}>
              {content[1].title}
            </P>
            <Ul skin='checkmark'>
              <Li mt={2}>{content[1].valueText}</Li>
              <Li mt={{ xs: 2, md: 4 }}>{content[1].hoverText}</Li>
              <Li mt={{ xs: 2, md: 4 }}>{content[1].altText}</Li>
            </Ul>

            <Button
              skin='primary'
              mt={{ xs: 6, md: 9 }}
              onClick={() =>
                redirectWithTracking(
                  YELP_BUSINESS_LINK,
                  content[2].title,
                  'Hero',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[2].title}
              <FluidImage src={arrow} alt='CTA arrow' />
            </Button>
          </Hidden>
        </Box>

        <FlexBox className='right-section-container'>
          <Carousel content={carouselContent} />
        </FlexBox>

        <Visible xs className='left-section-container-sm'>
          <P fontWeight='bold' fontSize='large' mt={8}>
            {content[1].title}
          </P>
          <Ul skin='checkmark'>
            <Li mt={2}>{content[1].valueText}</Li>
            <Li mt={{ xs: 2, md: 4 }}>{content[1].hoverText}</Li>
            <Li mt={{ xs: 2, md: 4 }}>{content[1].altText}</Li>
          </Ul>

          <Button
            skin='primary'
            mt={{ xs: 6, md: 7 }}
            onClick={() =>
              redirectWithTracking(
                YELP_BUSINESS_LINK,
                content[2].title,
                'Hero',
                true
              )}
            title='https://business.yelp.com'
          >
            {content[2].title}
            <FluidImage
              src='https://cms.cloudinary.vpsvc.com/image/upload/v1735043292/digital/promotions/yelp-pdp/arrow-top-right.svg'
              alt='CTA arrow'
            />
          </Button>
        </Visible>
      </FlexBox>
    </>
  )
}

export default Section1
