import { FlexBox, FluidImage, H3, P } from '@vp/swan'
import { BenefitItemProps } from './types'

export const BenefitItem = ({
  icon,
  title,
  description,
  citation,
  bgc,
  className = '',
}: BenefitItemProps) => {
  return (
    <FlexBox
      flexDirection='column'
      bgc={bgc !== 'red' ? bgc : undefined}
      gap={5}
      className={`flex-box ${bgc === 'red' ? 'bg-red' : ''}  ${className}`}

    >
      <FluidImage
        src={icon}
        alt={title}
        className='benefit-item-img'
      />
      <H3 m={0} className={bgc === 'red' ? 'text-white' : ''}>
        {title}
      </H3>
      <P m={0} className={bgc === 'red' ? 'text-white' : ''}>
        {description}
        {citation && citation}
      </P>
    </FlexBox>
  )
}
