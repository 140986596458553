import {
  FluidImage,
  Banner,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  P,
  Hidden,
  Box,
  FlexBox,
  useScreenClass,
  H2,
} from '@vp/swan'
import { useEffect } from 'react'
import { SectionProps } from './types'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
const Section2 = ({ content }: SectionProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'

  useEffect(() => {
    if (isMobile) {
      gsap.fromTo(
        '.section2-text-container',
        { opacity: 0, y: 200 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: '.block--section2',
            start: '150px center', // Start animation when the top of the section reaches the bottom of the viewport
            end: '+=100', // End animation when the top of the section reaches the center
            scrub: 1, // Smooth animation as you scroll
            // markers: true,
            id: 'section-2-text-container',
          },
        }
      )
    }
  }, [isMobile])
  /* const [isHoveringRight, setIsHoveringRight] = useState(false);

  const divRef = useRef(null);

  const handleMouseMove = (e: any) => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left; // Mouse position relative to the div
      const isOnRightSide = mouseX > rect.width * 0.6; // Adjust the percentage as needed (e.g., 0.75 for the rightmost 25%)
      setIsHoveringRight(isOnRightSide);
    } else setIsHoveringRight(false);
  }; */

  return (
    <Box
      className='block--section2'
      mt={{ xs: 10, md: 11 }}
      // onMouseMove={(e: any) => handleMouseMove(e)}
      // onMouseLeave={() => setIsHoveringRight(false)}
      // ref={divRef}
    >
      <Hidden xs>
        <Banner className='section2-banner' variant='full-width-image'>
          <BannerTextContainer
            style={{ placeSelf: 'end' }}
            className='banner-text-container'
          >
            <BannerText
              style={{ maxWidth: '450px' }}
              ml={0}
              data-aos='fade-right'
              data-aos-anchor-placement='top-center'
              data-aos-offset='-200'
              data-aos-duration='800'
            >
              <BannerTitle>{content[0].title} </BannerTitle>
              <BannerDescription>
                <P>
                  {content[0].description}{' '}
                  <span className='text-citation'>5</span>
                </P>
              </BannerDescription>
              {content[0].icons && (
                <BannerLinks>
                  <FluidImage
                    src={content[0].icons[1].secureUrl}
                    alt='reviews stars'
                    style={{ width: '195px' }}
                  />
                </BannerLinks>
              )}
            </BannerText>
          </BannerTextContainer>
          {content[0].icons && (
            <BannerImageContainer>
              <BannerImage>
                <FluidImage
                  src={content[0].icons[0].secureUrl}
                  alt='section 2 background desktop'
                  style={{
                    borderRadius: '16px',
                    objectPosition: 'center -160px',
                  }}
                />
              </BannerImage>
            </BannerImageContainer>
          )}
        </Banner>
      </Hidden>

      <Hidden sm md lg xl>
        <FlexBox className='relative block--wfull' flexDirection='column'>
          {content[0].icons && (
            <FluidImage
              src={content[0].icons[0].secureUrl}
              alt='section 2 background mobile'
              className='bg-img'
            />
          )}

          <Box
            p={{ xs: 5, md: 6 }}
            className='bg-white section2-text-container'
          >
            <H2>{content[0].title}</H2>
            <P m={0}>
              {content[0].description} <span className='text-citation'>5</span>
            </P>
            {content[0].icons && (
              <FluidImage
                src={content[0].icons[1].secureUrl}
                alt='reviews stars'
                style={{ width: '195px', margin: 'auto', display: 'block' }}
                mt={4}
              />
            )}
          </Box>
        </FlexBox>
      </Hidden>

      {/*   <Popover
        img={content[1].icons[0].secureUrl}
        title={content[1].title}
        link={{
          text: content[1].description,
          href: '#',
        }}
        id="popover1"
        position={{
          top: '20%',
          right: '10%',
        }}
        isDisplayed={isHoveringRight}
      /> */}
    </Box>
  )
}

export default Section2
