import { BasicCollapsible, Accordion, Li, Ol } from '@vp/swan'

import { SectionProps } from './types'

const Section5 = ({ content }: SectionProps) => {
  return (
    <Accordion
      mt={{ xs: 10, md: 12 }}
      defaultExpandedCollapsibles={{ 'item-1': true }}
      className='block--section5'
    >
      <BasicCollapsible collapsibleId='item-1' heading={content[0].title}>
        <Ol className='list--section5'>
          <Li textColor='info'>{content[0].description}</Li>
          <Li textColor='info'>{content[0].valueText}</Li>
          <Li textColor='info'>{content[0].hoverText}</Li>
          <Li textColor='info'>{content[0].altText}</Li>
          <Li textColor='info'>{content[1].title}</Li>
          <Li textColor='info'>{content[1].description}</Li>
        </Ol>
      </BasicCollapsible>
    </Accordion>
  )
}

export default Section5
