import {
  FluidImage,
  P,
  Box,
  Card,
  Carousel,
  CarouselSlide,
  Button,
  FlexBox,
  Hidden,
  useScreenClass,
  H2,
} from '@vp/swan'
import { useEffect, useState } from 'react'
import { SectionProps, TestimonialItemProps } from './types'
import { YELP_BUSINESS_LINK } from '../const/const'
import { redirectWithTracking } from '../helpers/redirectWithTracking'

const Section4 = ({ content, locale }: SectionProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'
  const [textHeight, setTextHeight] = useState('78px')

  useEffect(() => {
    if (locale === 'fr-CA' && !isMobile) setTextHeight('165px')
    else if (locale === 'fr-CA' && isMobile) setTextHeight('110px')
    else setTextHeight('78px')
  }, [isMobile])

  const testimonials: TestimonialItemProps[] = []
  content
    .filter((item) => item.key?.toLowerCase().includes('testimonial'))
    .map((item) => {
      return testimonials.push({
        avatar: item.icons ? String(item.icons[0]?.secureUrl) : '',
        text: item.title,
        author: String(item.description),
      })
    })

  return (
    <Box mt={{ xs: 10, md: 11 }} className='block--section4'>
      <Box className='block--centered'>
        <FlexBox
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={4}
          className='section4-top-section'
        >
          <center>
            <H2>{content[0].title}</H2>
            <P>{content[0].description}</P>
          </center>
          <Hidden xs sm>
            <Button
              skin='primary'
              onClick={() =>
                redirectWithTracking(
                  YELP_BUSINESS_LINK,
                  String(content[0].valueText),
                  'YelpJourney',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[0].valueText}
              <FluidImage
                src='https://cms.cloudinary.vpsvc.com/image/upload/v1735043292/digital/promotions/yelp-pdp/arrow-top-right.svg'
                alt='CTA arrow'
              />
            </Button>
          </Hidden>
        </FlexBox>

        <Carousel
          mt={{ xs: 4, sm: 6, md: 11 }}
          mx={{ sm: 8, md: 10, lg: 0 }}
          slidesToShow={{ xs: 1, sm: 2, lg: 3 }}
          gridGutters
          accessibleTextForPrevious='Go to previous slide'
          accessibleTextForNext='Go to next slide'
          progressIndicator='dots'
          // all accessible text should be localized
        >
          {testimonials.map((testimonial, i) => (
            <CarouselItem
              key={i}
              avatar={testimonial.avatar}
              text={testimonial.text}
              author={testimonial.author}
              textHeight={textHeight}
            />
          ))}
        </Carousel>

        <Hidden md lg xl>
          <center>
            <Button
              skin='primary'
              mt={6}
              onClick={() =>
                redirectWithTracking(
                  YELP_BUSINESS_LINK,
                  String(content[0].valueText),
                  'YelpJourney',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[0].valueText}
              {content[0].icons && (
                <FluidImage src={content[0].icons[0].secureUrl} alt='CTA arrow' />
              )}
            </Button>
          </center>
        </Hidden>
      </Box>
    </Box>
  )
}

type CarouselItemProps = {
  avatar: string;
  text: string;
  author: string;
  textHeight?: string;
}
const CarouselItem = ({
  avatar,
  text,
  author,
  textHeight,
}: CarouselItemProps) => {
  return (
    <CarouselSlide>
      <Card
        fullBleed
        overflow='hidden'
        p={{ xs: 4, sm: 6 }}
        bgc='info'
        className='carousel-item'
      >
        <Hidden xs>
          <FluidImage src={avatar} alt={author} style={{ width: '72px' }} />
        </Hidden>
        <P
          fontSize='large'
          fontWeight='bold'
          style={{
            minHeight: textHeight,
          }}
        >
          {text}
        </P>
        <P mt={6}>{author}</P>
      </Card>
    </CarouselSlide>
  )
}

export default Section4
