import {
  FluidImage,
  P,
  FlexBox,
  Box,
  Li,
  Ul,
  Button,
  Hidden,
  H2,
} from '@vp/swan'
import { SectionProps } from './types'
import { BenefitItem } from './benefit-item'
import { VISTA_SIGNS_POSTERS_PRODUCT_LINK, YELP_BUSINESS_LINK } from '../const/const'
import { redirectWithTracking } from '../helpers/redirectWithTracking'

const Section3 = ({ content, locale }: SectionProps) => {
  // const [isHovered, setIsHovered] = useState(false);

  return (
    <Box className='block--section3'>
      <FlexBox mt={{ xs: 10, md: 11 }} className='section3-container'>
        <Box className='relative left-section-container'>
          <H2>{content[0].title}</H2>
          <P>{content[0].description}</P>
          <Hidden xs>
            <P fontWeight='bold' fontSize='large' mt={{ xs: 2, md: 4 }}>
              {content[2].title}
            </P>
            <Ul skin='checkmark'>
              <Li mt={2}>{content[2].valueText}</Li>
              <Li mt={{ xs: 2, md: 4 }}>{content[2].hoverText}</Li>
              <Li mt={{ xs: 2, md: 4 }}>{content[2].altText}</Li>
            </Ul>

            <FlexBox gap={4} className='cta-container' mt={{ xs: 6, md: 8 }}>
              <Button
                skin='primary'
                onClick={() =>
                  redirectWithTracking(
                    YELP_BUSINESS_LINK,
                    String(content[0].valueText),
                    'GrowOnline',
                    true
                  )}
                title='https://business.yelp.com'
              >
                {content[0].valueText}
                {content[0].icons &&
                  <FluidImage
                    src={content[0].icons[2].secureUrl}
                    alt='CTA arrow'
                  />}
              </Button>

              <Button
                skin='secondary'
                onClick={() =>
                  redirectWithTracking(
                    VISTA_SIGNS_POSTERS_PRODUCT_LINK(String(locale)),
                    String(content[0].hoverText),
                    'GrowOnline'
                  )}
              >
                {content[0].hoverText}
              </Button>
            </FlexBox>
          </Hidden>
        </Box>

        <FlexBox
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
          className='relative bg-img-container'
          flexDirection='column'
          id='parent-box'
        >
          {content[0].icons &&
            <FluidImage
              src={content[0].icons[0].secureUrl}
              alt='section 3 background image'
              className='bg-img'
            />}

          <Hidden xs sm md>
            <Box
              p={{ xs: 5, md: 6 }}
              className='bg-white section3-text-container'
              data-aos='fade-up'
              data-aos-anchor-placement='top-center'
              data-aos-anchor='#parent-box'
              data-aos-offset='-400'
            >
              <P m={0} style={{ textAlign: 'left' }}>
                {content[0].altText}
              </P>
              {content[0].icons &&
                <FluidImage
                  src={content[0].icons[1].secureUrl}
                  alt='reviews stars'
                  style={{ width: '195px' }}
                  mt={4}
                />}
            </Box>
          </Hidden>

          <Hidden lg xl>
            <Box
              p={{ xs: 5, md: 6 }}
              className='bg-white section3-text-container'
              data-aos='fade-up'
              data-aos-anchor-placement='top-center'
              data-aos-anchor='#parent-box'
              data-aos-offset='-200'
            >
              <P m={0} style={{ textAlign: 'left' }}>
                {content[0].altText}
              </P>
              {content[0].icons &&
                <FluidImage
                  src={content[0].icons[1].secureUrl}
                  alt='reviews stars'
                  style={{ width: '195px' }}
                  mt={4}
                />}
            </Box>
          </Hidden>

          {/* <Popover
            img={content[1].icons[0].secureUrl}
            title={content[1].title}
            link={{
              text: content[1].description,
              href: '#',
            }}
            id="popover2"
            position={{
              top: '30%',
              right: '50%',
            }}
            isDisplayed={isHovered}
            arrowPosition="right"
          /> */}
        </FlexBox>
      </FlexBox>

      <div className='flex-boxes-container'>
        <FlexBox
          gap={4}
          pt={4}
          pr={4}
          pb={4}
          pl={0}
          className='bg-white relative flex-boxes'
        >
          {content[3].icons &&
            <BenefitItem
              icon={String(content[3].icons[0].secureUrl)}
              title={content[3].title}
              description={String(content[3].description)}
              citation={<span className='text-citation'>6</span>}
              bgc='accent'
              className='flex-box1'
            />}

          {content[4].icons &&
            <BenefitItem
              icon={String(content[4].icons[0].secureUrl)}
              title={content[4].title}
              description={String(content[4].description)}
              bgc='accent'
              className='flex-box2'
            />}

          {content[5].icons &&
            <BenefitItem
              icon={String(content[5].icons[0].secureUrl)}
              title={content[5].title}
              description={String(content[5].description)}
              bgc='red'
              className='flex-box3'
            />}
        </FlexBox>
      </div>

      <Hidden sm md lg xl>
        <center>
          <P fontWeight='bold' fontSize='large' mt={6}>
            {content[2].title}
          </P>
        </center>
        <Ul skin='checkmark'>
          <Li mt={2}>{content[2].valueText}</Li>
          <Li mt={{ xs: 2, md: 4 }}>{content[2].hoverText}</Li>
          <Li mt={{ xs: 2, md: 4 }}>{content[2].altText}</Li>
        </Ul>

        <FlexBox
          gap={5}
          flexDirection='column'
          justifyContent='center'
          mt={6}
          style={{ maxWidth: '280px', margin: '0 auto' }}
        >
          <Button
            skin='primary'
            className='block--wfull'
            onClick={() =>
              redirectWithTracking(
                YELP_BUSINESS_LINK,
                String(content[0].valueText),
                'GrowOnline',
                true
              )}
            title='https://business.yelp.com'
          >
            {content[0].valueText}
            {content[0].icons &&
              <FluidImage src={content[0].icons[2].secureUrl} alt='CTA arrow' />}
          </Button>

          <Button
            skin='secondary'
            className='block--wfull'
            onClick={() =>
              redirectWithTracking(
                VISTA_SIGNS_POSTERS_PRODUCT_LINK(String(locale)),
                String(content[0].hoverText),
                'GrowOnline'
              )}
          >
            {content[0].hoverText}
          </Button>
        </FlexBox>
      </Hidden>
    </Box>
  )
}

export default Section3
