import AOS from 'aos'
import { BoundedContent, Breadcrumbs, Breadcrumb, Link } from '@vp/swan'
import Redirect from '../common/ui/redirect'
import Section1 from './_components/section1'
import Section2 from './_components/section2'
import Section3 from './_components/section3'
import Section4 from './_components/section4'
import Section5 from './_components/section5'
import { YelpPdpPageDataModel } from './models/yelpPdp/yelpPdpPageData.model'
import { useEffect } from 'react'
import { trackYelpPdpPageLoad } from './helpers/YelpPdpPageAnalytics'
import { stylesheet as yelpPdpPageStylesheet } from './yelpPdpPage.scss'
import { stylesheet as aosStylesheet } from './aos.css'
import { useStyles } from '@vp/ubik-context'

const YelpPdpPage = ({ pageContext }: any) => {
  useStyles(yelpPdpPageStylesheet)
  useStyles(aosStylesheet)

  const { yelpPdpContent, /* seo, */ breadcrumbs, locale } =
    pageContext.pageData as YelpPdpPageDataModel

  useEffect(() => {
    AOS.init({
      delay: 100,
    })
    trackYelpPdpPageLoad()
  }, [])

  const section1Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section1')
  )
  const section2Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section2')
  )
  const section3Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section3')
  )
  const section4Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section4')
  )
  const section5Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section5')
  )

  return (
    <>
      <Redirect isRedirecting={false} redirectPath={null}>
        <>

          {/* <SEOContainer content={seo} /> */}
          <BoundedContent
            pb={{ xs: 4, sm: 6, md: 8 }}
            pt={4}
            px={{ xs: 5, md: 7 }}
          >
            <Breadcrumbs mb={10}>
              <Breadcrumb>
                <Link href='/'>{breadcrumbs[0].name}</Link>
              </Breadcrumb>
              <Breadcrumb>
                <Link href='#' aria-current='page'>
                  {breadcrumbs[0].description.description}
                </Link>
              </Breadcrumb>
              <Breadcrumb>
                <Link href='#' aria-current='page'>
                  {breadcrumbs[0].valueText}
                </Link>
              </Breadcrumb>
            </Breadcrumbs>

            <Section1 content={section1Content} />
            <Section2 content={section2Content} />
            <Section3 content={section3Content} locale={locale} />
            <Section4 content={section4Content} locale={locale} />
            <Section5 content={section5Content} />
          </BoundedContent>
        </>
      </Redirect>
    </>
  )
}

export default YelpPdpPage
